// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    TF_API : '/v3',
    TF_API_ATUALIZA : '/v3/atualizador-produto',
    TF_API_OCTADESK : '/api',
    TF_API_PRODUTO : '/backend',
    TF_API_IMPORTADOR_ARQUIVO : '/v3/importador-arquivos'
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
  
